import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function DeepLinkHandler() {
  const location = useLocation();

  useEffect(() => {
    const handleDeepLink = () => {
      // story 경로를 포함하여 처리
      const path = location.pathname; // /story/1 형식 그대로 사용
      const params = location.search;

      // 모바일 OS 감지
      const isAndroid = /Android/i.test(navigator.userAgent);
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

      // 앱 스킴 및 스토어 URL 설정
      const appScheme = "ccbb://";
      const androidPackage = "com.yourcompany.ccbb";
      const iosStoreUrl = "https://apps.apple.com/app/6739578010";
      const androidStoreUrl = `https://play.google.com/store/apps/details?id=${androidPackage}`;

      // 딥링크 URL 생성 (story를 포함한 전체 경로 사용)
      const deepLinkUrl = `${appScheme}${path.substring(1)}${params}`;

      if (isAndroid) {
        // Android 인텐트 방식 수정
        const intentUrl =
          `intent://${path.substring(1)}${params}#Intent;` +
          `scheme=ccbb;package=${androidPackage};end;`;
        window.location.href = intentUrl;

        // 앱이 실행되지 않으면 스토어로 이동
        setTimeout(() => {
          window.location.href = androidStoreUrl;
        }, 2500);
      } else if (isIOS) {
        // iOS Universal Links 및 Custom URL Scheme 모두 시도
        const universalLink = `https://ssbb.cc${path}${params}`; // Universal Link URL

        // 먼저 Universal Link 시도
        window.location.href = universalLink;

        // Universal Link가 실패하면 Custom URL Scheme 시도
        setTimeout(() => {
          window.location.href = deepLinkUrl;

          // Custom URL Scheme도 실패하면 App Store로 이동
          setTimeout(() => {
            window.location.href = iosStoreUrl;
          }, 2500);
        }, 1000);
      } else {
        // 데스크톱의 경우 메시지 표시
        console.log("Desktop environment detected");
      }
    };

    handleDeepLink();
  }, [location]);

  // 로딩 상태를 표시할 수 있는 UI
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mx-auto mb-4"></div>
        <h2 className="text-xl font-semibold text-gray-900 mb-2">
          앱으로 이동중입니다...
        </h2>
        <p className="text-gray-600 text-sm">
          잠시만 기다려주세요. 앱이 설치되어 있지 않은 경우 스토어로 이동됩니다.
        </p>
      </div>
    </div>
  );
}

export default DeepLinkHandler;
