import React from "react";

function Home() {
  return (
    <main
      className="min-h-screen pt-16 px-6 pb-20 bg-center bg-cover bg-no-repeat"
      style={{
        backgroundImage: 'url("/bg.png")',
      }}
    >
      {/* 전체 컨텐츠를 감싸는 컨테이너 */}
      <div className="max-w-[1100px] mx-auto min-h-[calc(100vh-144px)] flex flex-col items-center">
        {/* 메인 컨텐츠 영역 */}
        <div className="flex-grow flex flex-col items-center">
          {/* 로고 */}
          <div>
            <img
              src="/logo.png"
              alt="시시비비"
              width={88}
              height={88}
              priority
            />
          </div>

          {/* 메인 타이틀 */}
          <div className="text-center mt-8">
            <h1 className="font-noto font-extrabold text-white mb-4">
              <span className="inline-block text-[38.4px] leading-[43.4px] md:text-[80px] md:leading-[1.2]">
                아니,&nbsp;{" "}
              </span>
              <span className="inline-block bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 text-transparent bg-clip-text text-[38.4px] leading-[43.4px] md:text-[80px] md:leading-[1.2] font-black">
                엄마가&nbsp;
              </span>
              <span className="inline-block text-[38.4px] leading-[43.4px] md:text-[80px] md:leading-[1.2]">
                이러는데
              </span>
            </h1>
            <h2 className="font-noto font-extrabold tracking-[-2.5px] text-[38.4px] leading-[43.4px] md:text-[80px] md:leading-[1.25] text-white flex items-center justify-center gap-2">
              내가 이상한거야{" "}
              <span className="text-[30px] md:text-[80px]">🤯</span>?
            </h2>
          </div>

          {/* 서브 텍스트 */}
          <div className="text-center mt-8 mb-4">
            <p className="text-white text-2xl md:text-[32px] mb-2">
              내 잘못, 니 잘못 헷갈릴땐!
            </p>
            <p className="text-white text-2xl md:text-[32px]">
              시시비비에 물어보자!
            </p>
          </div>

          {/* 앱스토어 다운로드 버튼 */}
          <div className="mt-8">
            <img
              src="/download.svg"
              alt="Download on the App Store"
              width={160}
              height={56}
              className="h-[56px] md:h-[56px] w-auto"
              priority
            />
          </div>

          {/* 대화창 이미지들 */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-[60px] mt-12">
            <div className="transform rotate-[-3deg] hover:rotate-0 transition-transform duration-300">
              <img
                src="/conversation1.png"
                alt="대화창 1"
                width={640}
                height={782}
                className="w-full h-auto drop-shadow-xl"
              />
            </div>
            <div className="transform rotate-[2deg] hover:rotate-0 transition-transform duration-300">
              <img
                src="/conversation2.png"
                alt="대화창 2"
                width={640}
                height={781}
                className="w-full h-auto drop-shadow-xl"
              />
            </div>
            <div className="transform rotate-[-2deg] hover:rotate-0 transition-transform duration-300">
              <img
                src="/conversation3.png"
                alt="대화창 3"
                width={640}
                height={792}
                className="w-full h-auto drop-shadow-xl"
              />
            </div>
          </div>
        </div>

        {/* 푸터 */}
        <div className="w-full text-center">
          <p className="text-white text-base mb-1">
            © 2024 팀매버릭. All Rights reserved.
          </p>
          <p className="text-white/75 text-sm">support@ssbb.cc</p>
        </div>
      </div>
    </main>
  );
}

export default Home;
