import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import DeepLinkHandler from "./pages/DeepLinkHandler";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import MarketingConsent from "./pages/MarketingConsent";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/marketing" element={<MarketingConsent />} />
        <Route path="/story/*" element={<DeepLinkHandler />} />
      </Routes>
    </Router>
  );
}

export default App;
