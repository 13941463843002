import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 font-sans leading-relaxed">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-10">
        개인정보 수집 및 이용 동의 약관
      </h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제1조 개인정보의 수집 항목
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">필수 수집 항목:</span> 이메일 주소, 성별,
          나이, 닉네임 (자동 생성됨)
        </p>
        <p className="text-gray-600">
          <span className="font-bold">선택 수집 항목:</span> 서비스 개선을 위한
          기타 정보 (설문 등)
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제2조 개인정보 수집 및 이용 목적
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">회원 식별 및 인증:</span> 회원 가입,
          로그인 및 서비스 제공을 위해 필요한 인증
        </p>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">서비스 제공 및 개선:</span> 갈등 해결
          컨텐츠 제공, 피드백 수집 및 서비스 품질 향상
        </p>
        <p className="text-gray-600">
          <span className="font-bold">이벤트 및 알림 제공:</span> 서비스 내
          이벤트 정보 및 혜택 알림
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제3조 개인정보의 보유 및 이용 기간
        </h2>
        <p className="text-gray-600 mb-2">
          회원 가입 기간 동안 개인정보를 보유하며, 회원 탈퇴 시 즉시 삭제합니다.
        </p>
        <p className="text-gray-600">
          법률에 따른 보관 필요 시 해당 기간 동안 정보를 안전하게 보관합니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제4조 개인정보의 제3자 제공
        </h2>
        <p className="text-gray-600 mb-2">
          원칙적으로 회원의 개인정보는 외부에 제공하지 않습니다.
        </p>
        <p className="text-gray-600">
          단, 법적 요청이나 회원의 동의가 있는 경우에 한해 제공될 수 있습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제5조 동의를 거부할 권리 및 불이익 사항
        </h2>
        <p className="text-gray-600 mb-2">
          사용자는 개인정보 제공에 대해 동의를 거부할 권리가 있습니다.
        </p>
        <p className="text-gray-600">
          다만, 동의를 거부할 경우 서비스 이용이 제한될 수 있습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제6조 개인정보 보호 및 관리 방침
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">암호화 저장:</span> 개인정보는 안전하게
          암호화하여 저장하며, 불법 접근을 방지하기 위해 최선을 다합니다.
        </p>
        <p className="text-gray-600">
          <span className="font-bold">접근 권한 제한:</span> 개인정보에 대한
          접근 권한을 최소화하고, 내부적으로 접근 기록을 관리합니다.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
