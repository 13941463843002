import React from "react";

const MarketingConsent = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 font-sans leading-relaxed">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-10">
        개인정보 마케팅 활용 동의 약관
      </h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제1조 개인정보 수집 항목
        </h2>
        <p className="text-gray-600">
          <span className="font-bold">마케팅 목적으로 수집되는 항목:</span>{" "}
          이메일 주소, 성별, 나이, 서비스 이용 기록
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제2조 개인정보의 이용 목적
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">이벤트 및 혜택 알림:</span> 서비스에서
          진행하는 이벤트 정보 및 혜택을 안내하기 위한 이메일 발송
        </p>
        <p className="text-gray-600">
          <span className="font-bold">맞춤형 광고 제공:</span> 회원의 관심사에
          맞춘 광고 및 정보 제공
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제3조 개인정보의 보유 및 이용 기간
        </h2>
        <p className="text-gray-600 mb-2">
          마케팅 활용 목적의 개인정보는 회원의 동의 철회 시까지 보유 및
          이용합니다.
        </p>
        <p className="text-gray-600">
          회원이 동의를 철회하거나 탈퇴할 경우, 해당 정보를 즉시 파기합니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제4조 개인정보의 제3자 제공
        </h2>
        <p className="text-gray-600 mb-2">
          원칙적으로 회원의 동의 없이 제3자에게 제공되지 않습니다.
        </p>
        <p className="text-gray-600">
          마케팅 활동과 관련된 파트너에게 정보가 제공될 수 있으며, 이 경우
          반드시 사전에 동의를 구합니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제5조 동의를 거부할 권리 및 불이익 사항
        </h2>
        <p className="text-gray-600 mb-2">
          사용자는 개인정보의 마케팅 활용에 대한 동의를 거부할 수 있습니다.
        </p>
        <p className="text-gray-600">
          다만, 동의를 거부할 경우 이벤트 및 맞춤형 혜택 알림 서비스 이용이
          제한될 수 있습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제6조 마케팅 활용 동의 철회 방법
        </h2>
        <p className="text-gray-600">
          회원은 언제든지 서비스 내 설정 메뉴를 통해 마케팅 활용 동의를 철회할
          수 있으며, 철회 즉시 해당 정보는 마케팅 목적으로 사용되지 않습니다.
        </p>
      </section>
    </div>
  );
};

export default MarketingConsent;
