import React from "react";

const TermsOfService = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 font-sans leading-relaxed">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-10">
        시시비비 서비스 이용 약관
      </h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제1조 목적
        </h2>
        <p className="text-gray-600">
          이 약관은 시시비비(이하 "서비스")의 이용 조건 및 절차, 회원의 권리와
          의무 등을 규정함을 목적으로 하며, 서비스와 회원 간의 법적 관계를
          명확히 규정합니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제2조 용어 정의
        </h2>
        <ul className="list-disc pl-5 text-gray-600">
          <li>
            <span className="font-bold">서비스:</span> 시시비비가 제공하는 모든
            기능 및 콘텐츠, 사용자 간의 갈등 투표 및 감정 표현을 포함합니다.
          </li>
          <li>
            <span className="font-bold">회원:</span> 본 약관에 동의하고 서비스를
            이용하는 모든 개인을 의미합니다.
          </li>
          <li>
            <span className="font-bold">관리자:</span> 서비스의 유지, 관리 및
            회원의 요청 처리를 담당하는 자를 의미합니다.
          </li>
          <li>
            <span className="font-bold">콘텐츠:</span> 회원이 서비스에 업로드한
            텍스트, 이미지 등의 모든 형태의 자료를 포함합니다.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제3조 회원 가입 및 탈퇴
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">회원 가입:</span> 사용자는 SSO(카카오톡,
          구글, 애플)를 통해 간편하게 가입할 수 있으며, 가입 시 개인정보(이메일,
          성별 등)가 수집됩니다. 사용자는 약관 동의 후 서비스 이용이 가능합니다.
        </p>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">회원 탈퇴:</span> 회원은 언제든지 서비스
          내 설정 메뉴를 통해 탈퇴 요청을 할 수 있으며, 탈퇴 시 개인정보와
          관련된 모든 데이터는 영구 삭제됩니다. 단, 서비스 운영에 필요한
          익명화된 데이터는 통계 목적으로 유지될 수 있습니다.
        </p>
        <p className="text-gray-600">
          <span className="font-bold">탈퇴 후 재가입:</span> 탈퇴 후 동일 SSO
          수단으로 재가입 시 일정 기간 동안 제한이 있을 수 있습니다(최대 30일).
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제4조 서비스 제공 및 변경
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">서비스 제공:</span> 시시비비는 사용자가
          갈등을 등록하고, 다른 사용자로부터 의견을 받을 수 있는 기능을
          제공합니다. 회원은 다양한 갈등 상황에 대해 투표와 감정 표현을 통해
          의견을 나눌 수 있습니다.
        </p>
        <p className="text-gray-600">
          <span className="font-bold">서비스 변경:</span> 서비스는 운영상 필요에
          따라 사전 통보 후 기능을 추가하거나 변경할 수 있습니다. 변경 사항은
          사전에 공지하며, 변경된 서비스 이용에 동의하지 않는 회원은 탈퇴할 수
          있습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제5조 회원의 의무
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">법령 준수:</span> 회원은 서비스 이용 시
          관련 법령 및 본 약관을 준수해야 하며, 타인의 권리나 명예를 훼손하지
          않아야 합니다.
        </p>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">계정 관리:</span> 회원은 본인의 계정을
          안전하게 관리할 책임이 있으며, 타인에게 계정을 공유하거나 양도할 수
          없습니다. 비밀번호 도용이나 계정 해킹이 의심될 경우 즉시 관리자에게
          신고해야 합니다.
        </p>
        <p className="text-gray-600">
          <span className="font-bold">부적절한 행위 금지:</span> 회원은 허위
          사실 유포, 타인 비방, 불법 콘텐츠 업로드 등 부적절한 행위를 해서는 안
          됩니다. 위반 시 서비스 이용이 제한될 수 있습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제6조 서비스 이용 제한
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">이용 제한 기준:</span> 회원이 서비스 운영
          방침을 위반하거나, 타인의 권리를 침해하는 경우, 서비스 이용이 제한될
          수 있습니다.
        </p>
        <p className="text-gray-600">
          <span className="font-bold">이용 제한 절차:</span> 이용 제한 시
          서비스는 해당 회원에게 사유를 통지하며, 회원은 이에 대해 이의 신청을
          할 수 있습니다. 관리자는 이의 신청을 검토하고 최종 결정을 내립니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제7조 개인정보 처리 방침
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">개인정보 수집:</span> 서비스는 회원의
          이메일, 성별, 나이 등의 개인정보를 수집하며, 이는 서비스 제공 및 품질
          향상을 위해 사용됩니다.
        </p>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">개인정보 보호:</span> 수집된 개인정보는
          서비스 제공 목적 이외의 용도로 사용되지 않으며, 회원의 동의 없이
          외부로 공개되지 않습니다.
        </p>
        <p className="text-gray-600">
          <span className="font-bold">개인정보 삭제:</span> 회원 탈퇴 시 모든
          개인정보는 삭제되며, 법적 요청이 있는 경우를 제외하고 외부로 공개되지
          않습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제8조 면책 조항
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">서비스 면책:</span> 시시비비는 회원 간
          의견 교환에서 발생하는 모든 문제에 대해 법적 책임을 지지 않습니다.
          회원 상호 간의 갈등에 대해 회사는 개입하지 않으며, 회원 간의 문제는
          직접 해결해야 합니다.
        </p>
        <p className="text-gray-600">
          <span className="font-bold">불가항력:</span> 서비스 제공에 있어
          천재지변, 시스템 장애, 외부 공격 등으로 인한 서비스 중단 시 회사는
          책임을 지지 않습니다.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 border-b border-gray-200 pb-2 mb-4">
          제9조 분쟁 해결
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold">분쟁 발생 시:</span> 본 서비스 이용과
          관련하여 발생하는 모든 분쟁은 대한민국 법률을 따르며, 필요 시 관할
          법원에서 해결합니다.
        </p>
        <p className="text-gray-600">
          <span className="font-bold">분쟁 조정:</span> 분쟁이 발생할 경우,
          회원은 관리자에게 중재를 요청할 수 있으며, 관리자는 공정하게 이를
          조정하기 위해 노력합니다.
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
